import React from 'react'
import { Outlet } from 'react-router-dom'
import Footer from '../Components/Footer'
import Header from '../Components/Header'
import HostelReview from './HostelReview'
import Main from './Main'
import NewsReview from './News/NewsReview'

export default function Dashboard() {
    return (
        <>
            <Header />
            {/* <Main /> */}
            {/* <NewsReview /> */}
            {/* <HostelReview /> */}
            <Outlet />
            <Footer />
        </>
    )
}
