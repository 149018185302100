import React from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button, Checkbox, TextField } from '@mui/material'
import { ReactComponent as DownArrowIcon } from "../../../Icons/DownArrow.svg"
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { TimeField } from '@mui/x-date-pickers/TimeField';
export default function Services({ setStepperCount }) {



    return (
        <Box px={{ md: 10, xs: 3 }}>
            <Typography variant="h6_1">Select Preferred room</Typography>

            <Grid mt={3} mb={6} container rowGap={7}>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Typography variant='h7_1'>Flight details</Typography>
                    <Divider />
                    <Grid container justifyContent="space-between">
                        <Grid xs={12} sm={4}>
                            <InputLabel sx={{ mt: 3 }} variant="standard">Flight number</InputLabel>
                            <TextField fullWidth />
                        </Grid>
                        <Grid xs={12} sm={3.3}>
                            <InputLabel sx={{ mt: 3 }} variant="standard">Arrival date</InputLabel>
                            <DatePicker sx={{ width: "100%", '.MuiSvgIcon-root': { fill: "#1A2461" } }} format="LL" fullWidth />
                        </Grid>
                        <Grid xs={12} sm={2.2}>
                            <InputLabel sx={{ mt: 3 }} variant="standard">Arrival time</InputLabel>
                            <TimeField sx={{ width: "100%" }} />
                        </Grid>

                    </Grid>

                </Grid>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Typography variant='h7_1'>Landing Services (Optional)</Typography>
                    <Divider />
                    <Stack gap="10px" direction="row" justifyContent="space-between" alignItems="center" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Stack>
                            <Typography variant='h9_1' color="text_blue">Landing Service Package</Typography>
                            <Typography variant='h9_4' >Airport Pick-up
                                Medical Check- <br />
                                ICA Student Pass Collection<br />
                                Accompany to Apply for Bank Card<br />
                                Travel Card<br />
                                Phone Card, etc.<br />
                                (Weekdays 22:00-9:00 and weekends, holidays, extra $50)</Typography>
                        </Stack>
                        <Typography flexGrow={1} textAlign="end" variant='h6_1' color="neutral_3">S$ 400</Typography>
                    </Stack>
                </Grid>
                <Grid xs={12} sx={{ display: "flex", flexDirection: "column" }} rowGap={2} gap={2}>
                    <Typography variant='h7_1'>Other Chargeable Services (Optional)</Typography>
                    <Divider />
                    <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">Extra curriculum activities </Typography>
                    </Stack>
                    <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">Tuition Arrangement</Typography>
                    </Stack>
                    <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">English IELTS, SAT Training</Typography>
                    </Stack>
                    <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">University Course Training</Typography>
                    </Stack>
                    <Stack gap="10px" direction="row" alignItems="center" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Typography variant='h9_1' color="text_blue">Counselling</Typography>
                    </Stack>
                    <Stack gap="10px" direction="row" justifyContent="start" sx={{ border: "0.5px solid #1A2461", padding: "14px 23px", borderRadius: "8px" }}>
                        <Checkbox />
                        <Stack>
                            <Typography variant='h9_1' color="text_blue">Guardian Service</Typography>
                            <Typography variant='h9_4' > Primary and secondary schools and international schools $500/ month
                                Private universities $2000/ year.</Typography>

                        </Stack>
                    </Stack>
                </Grid>
            </Grid>

            <Stack my={5} direction="row" alignItems="center" justifyContent="space-between">
                <Button onClick={() => { setStepperCount(1) }} variant="standard">Back</Button>
                <Button onClick={() => { setStepperCount(2) }} variant="standard">Continue</Button>
            </Stack>
        </Box>
    )
}
