import React from 'react'
import CustomCarousel from '../Components/Carousel'
import Header from '../Components/Header'
import { Box, Stack, Button, Typography } from '@mui/material'
import { Steps } from "../Data/index"
import Announcement from '../Components/Main/Announcement'
import Hostels from '../Components/Main/Hostels'
import News from '../Components/Main/News'

export default function Main() {
    return (
        <>
            <Box sx={{ position: "relative" }}>
                <CustomCarousel btndisplay={true} />
                <Stack rowGap="50px" sx={{ position: "absolute", top: 0, transform: "translateY(50%)", left: 60 }} direction="column" justifyContent="center" textAlign="left" color="white" >
                    <Stack rowGap="10px">
                        <Typography variant="h3">Vinga Hall</Typography>
                        <Typography variant="h1">Learning & Living</Typography>
                        <Typography variant="h4_2">Your second Home during your college life</Typography>
                    </Stack>
                    <Button  variant="contained" sx={{ width: "230px", height: "80px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1%", fontSize: "24px" }}>Apply Now</Button>
                </Stack>
                <Box display="flex" justifyContent="center">
                    <Stack direction="row" columnGap={4} sx={{ boxShadow: "0px 11px 30px 0px rgba(0,0,0,0.1)", height: "200px", position: "absolute", bottom: "-100px", backgroundColor: "white", padding: "40px", borderRadius: "24px" }} >
                        {Steps?.map((item, index) => (
                            <>
                                <Stack gap="10px" direction="column" justifyContent="center" alignItem="center" maxWidth="172px">
                                    <Typography variant="h9_3" color="button">{item?.name}</Typography>
                                    <Box display="flex" justifyContent="center">
                                        <Box component="img" src={item?.icon}></Box>
                                    </Box>
                                    <Typography variant="h8_1">{item?.Description}</Typography>
                                </Stack>
                            </>
                        ))}
                    </Stack>
                </Box>
            </Box>
            <Announcement />
            <Hostels />
            <News />
        </>
    )
}
