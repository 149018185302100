import React from "react";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import Bgimg from "../Icons/bgimg.svg"
import { ReactComponent as CarouselLeftArrow } from "../Icons/carouselLeftArrow.svg"
import { ReactComponent as CarouselRightArrow } from "../Icons/carouselRightArrow.svg"
import { ReactComponent as ShareNewsIcon } from "../Icons/shareNewsIcon.svg"
import { ReactComponent as FavouritesNewsIcon } from "../Icons/favouritesNewsIcon.svg"

import { IconButton } from "@mui/material";
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import { Button, CardActionArea, CardActions, Grid } from '@mui/material';
import { Stack } from "@mui/system";


export default function CustomCardCarousel({ btndisplay }) {
    const images = [
        [
            {
                src: Bgimg,
            },
            {
                src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
            },
            {
                src: "https://images.pexels.com/photos/7968277/pexels-photo-7968277.jpeg?auto=compress&cs=tinysrgb&w=600",
            },
        ],
        [
            {
                src: Bgimg,
            },
            {
                src: "https://images.pexels.com/photos/5137969/pexels-photo-5137969.jpeg?auto=compress&cs=tinysrgb&w=600",
            },
            {
                src: "https://images.pexels.com/photos/7968277/pexels-photo-7968277.jpeg?auto=compress&cs=tinysrgb&w=600",
            },
        ]
    ];



    const CustomPrevArrow = ({ onClick }) => (

        <IconButton onClick={onClick} sx={{
            position: "absolute",
            top: "50%",
            left: btndisplay ? "15px" : "1000000px",
            zIndex: 2,
            border: "none",
            opacity: "50%",
            cursor: "pointer",
            transform: "translateY(-50%)",
        }} >
            <CarouselLeftArrow />
        </IconButton>
    );

    const CustomNextArrow = ({ onClick }) => (
        <IconButton onClick={onClick} sx={{
            position: "absolute",
            top: "50%",
            right: btndisplay ? "15px" : "1000000px",
            zIndex: 2,
            opacity: "50%",
            border: "none",
            cursor: "pointer",
            transform: "translateY(-50%)",
        }} >
            <CarouselRightArrow />
        </IconButton>
    );

    // const styles = {
    //     '.carousel .control-dots': {
    //         margin: '118px 0 !important'
    //     },

    //     '.carousel.control - dots.dot.selected': {
    //         width: '36px',
    //         height: '8px',
    //         borderRadius: '24px',
    //     }
    // }
    const styles = {
        controlDots: {
            margin: '118px 0 !important'
        },
        selectedDot: {
            width: '36px',
            height: '8px',
            borderRadius: '24px',
            background: "#434343",
            margin: '0 4px'
        },
        UnselectedDot: {
            width: '8px',
            height: '8px',
            borderRadius: '24px',
            background: "#FFFFFF",
            border: "1px solid #434343",
            margin: '0 4px'
        }
    };

    const Hostelstyles = {
        controlDots: {
            margin: '118px 0 !important'
        },
        selectedDot: {
            width: '36px',
            height: '8px',
            borderRadius: '24px',
            background: "#434343",
            margin: '0 4px'
        },
        UnselectedDot: {
            width: '8px',
            height: '8px',
            borderRadius: '24px',
            background: "#FFFFFF",
            border: "1px solid #434343",
            margin: '0 4px'
        }
    };


    return (
        <>
            <Carousel
                showThumbs={false}
                // infiniteLoop={true}
                // autoPlay={true}
                // interval={2000}
                showStatus={false}
                renderArrowPrev={(onClickHandler, hasPrev, label) =>
                    hasPrev && <CustomPrevArrow onClick={onClickHandler} />
                }
                renderArrowNext={(onClickHandler, hasNext, label) =>
                    hasNext && <CustomNextArrow onClick={onClickHandler} />
                }
                renderIndicator={(onClickHandler, isSelected, index, label) => {
                    const indicatorStyle = isSelected ? styles.selectedDot : styles.UnselectedDot
                    return (
                        <li
                            style={{ ...indicatorStyle, display: 'inline-block', cursor: 'pointer' }}
                            onClick={onClickHandler}
                            onKeyDown={onClickHandler}
                            value={index}
                            key={index}
                            role="button"
                            tabIndex={0}
                            aria-label={`${label} ${index + 1}`}
                        />
                    );
                }}
            >
                {images?.map((itm, index) => (
                    <>
                        <Grid container gap="30px" mt={5} pt={5} pb={10} justifyContent="center" marginBottom='0px' sx={{ background: "#EFF4FC" }}>
                            <Grid >
                                <Card sx={{ maxWidth: 412, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start", position: "relative" }}>
                                    <Stack direction="row" columnGap="10px" sx={{ position: "absolute", zIndex: "1", right: "25px", top: '25px' }}>
                                        <ShareNewsIcon />
                                        <FavouritesNewsIcon />
                                    </Stack>
                                    <CardActionArea>
                                        <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                            component="img"
                                            image={itm[0]?.src}
                                            alt="green iguana"
                                        />

                                        <CardContent >
                                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography gutterBottom variant="h7_1" component="div" color="#007ECA">
                                                    Title of News
                                                </Typography>
                                                <Typography variant="h9_4" color="#007ECA" sx={{ width: "42px", height: "24px", border: ".5px solid #007ECA", padding: "0 10px 26px 10px", borderRadius: "80px" }}>tag</Typography>
                                            </Stack>
                                            <Typography variant="h9_4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commod
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <Stack direction="row" justifyContent="end">
                                        <Button variant="contained" sx={{ width: "80px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>More</Button>
                                    </Stack>
                                </Card>
                            </Grid>
                            <Grid>
                                <Card sx={{ maxWidth: 412, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start", position: "relative" }}>
                                    <Stack direction="row" columnGap="10px" sx={{ position: "absolute", zIndex: "1", right: "25px", top: '25px' }}>
                                        <ShareNewsIcon />
                                        <FavouritesNewsIcon />
                                    </Stack> <CardActionArea>
                                        <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                            component="img"
                                            image={itm[1]?.src}
                                            alt="green iguana"
                                        />
                                        <CardContent >
                                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography gutterBottom variant="h7_1" component="div" color="#007ECA">
                                                    Title of News
                                                </Typography>
                                                <Typography variant="h9_4" color="#007ECA" sx={{ width: "42px", height: "24px", border: ".5px solid #007ECA", padding: "0 10px 26px 10px", borderRadius: "80px" }}>tag</Typography>
                                            </Stack>
                                            <Typography variant="h9_4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commod
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <Stack direction="row" justifyContent="end">
                                        <Button variant="contained" sx={{ width: "80px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>More</Button>
                                    </Stack>
                                </Card>
                            </Grid>
                            <Grid>
                                <Card sx={{ maxWidth: 412, p: 2, border: ".5px solid #737373", borderRadius: "20px", textAlign: "start", position: "relative" }}>
                                    <Stack direction="row" columnGap="10px" sx={{ position: "absolute", zIndex: "1", right: "25px", top: '25px' }}>
                                        <ShareNewsIcon />
                                        <FavouritesNewsIcon />
                                    </Stack>
                                    <CardActionArea>
                                        <CardMedia sx={{ borderRadius: "20px", height: "200px" }}
                                            component="img"
                                            image={itm[2]?.src}
                                            alt="green iguana"
                                        />
                                        <CardContent >
                                            <Stack direction="row" justifyContent="space-between" alignItems="center">
                                                <Typography gutterBottom variant="h7_1" component="div" color="#007ECA">
                                                    Title of News
                                                </Typography>
                                                <Typography variant="h9_4" color="#007ECA" sx={{ width: "42px", height: "24px", border: ".5px solid #007ECA", padding: "0 10px 26px 10px", borderRadius: "80px" }}>tag</Typography>
                                            </Stack>
                                            <Typography variant="h9_4">
                                                Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commod
                                            </Typography>
                                        </CardContent>
                                    </CardActionArea>
                                    <Stack direction="row" justifyContent="end">
                                        <Button variant="contained" sx={{ width: "80px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>More</Button>
                                    </Stack>
                                </Card>
                            </Grid>
                        </Grid>
                    </>
                ))}
            </Carousel>
        </>
    );
}
