import { createTheme, responsiveFontSizes } from "@mui/material/styles";


let Theme = createTheme({
    palette: {
        primary: {
            main: '#27bac4'
        },
        button: "#007eca",
        text_blue: "#1a2461",
        vinga_blue: "#515fa9",
        neutral_7: "#737373",
        neutral_4: "#424242",
        neutral_3: "#333333",
        green: "#52ad33",
        white: "#ffffff",
        red: "#c71919"
    },
    typography: {
        fontFamily: "SF Pro Display Regular",
        h1: {
            fontSize: 90,
            fontWeight: 700,
            letterSpacing: 1
        },
        h2: {
            fontSize: 40,
            fontWeight: 700
        },
        h3: {
            fontSize: 36,
            fontWeight: 400
        },
        h4_1: {
            fontSize: 32,
            fontWeight: 700,
            letterSpacing: .7,
            '@media (max-width:600px)': {
                fontSize: 26,
            },
        },
        h4_2: {
            fontSize: 32,
            fontWeight: 400,
            letterSpacing: .7,
            '@media (max-width:600px)': {
                fontSize: 26,
            },
        },
        h5: {
            fontSize: 28,
            fontWeight: 700
        },
        h6_1: {
            fontSize: 22,
            fontWeight: 700,
            letterSpacing: ".8px",
            '@media (max-width:600px)': {
                fontSize: 18,
            },
        },
        h6_2: {
            fontSize: 22,
            fontWeight: 400,
            '@media (max-width:600px)': {
                fontSize: 18,
            },
        },
        h6_3: {
            fontSize: 22,
            fontWeight: 500,
            letterSpacing: ".8px",
            '@media (max-width:600px)': {
                fontSize: 18,
            },
        },
        h7_1: {
            fontSize: 20,
            fontWeight: 700,
            letterSpacing: ".8px",
            '@media (max-width:600px)': {
                fontSize: 16,
            },
        },
        h7_2: {
            fontSize: 20,
            fontWeight: 400,
            '@media (max-width:600px)': {
                fontSize: 16,
            },
        },
        h7_3: {
            fontSize: 20,
            fontWeight: 500,
            '@media (max-width:600px)': {
                fontSize: 16,
            },
        },
        h8_1: {
            fontSize: 18,
            fontWeight: 700,
            letterSpacing: ".7px",
            '@media (max-width:600px)': {
                fontSize: 14,
            },
        },
        h8_2: {
            fontSize: 18,
            fontWeight: 500,
            '@media (max-width:600px)': {
                fontSize: 15,
            },
        },
        h9_1: {
            fontSize: 16,
            fontWeight: 700,
            letterSpacing: "1px",
            '@media (max-width:600px)': {
                fontSize: 14,
            },
        },
        h9_2: {
            fontSize: 15,
            fontWeight: 400,
            '@media (max-width:600px)': {
                fontSize: 14,
            },
        },
        h9_3: {
            fontWeight: 500,
            lfontSize: 16,
            letterSpacing: ".5px",
            '@media (max-width:600px)': {
                fontSize: 14,
            },
        },
        h9_4: {
            fontSize: 16,
            fontWeight: 400,
            letterSpacing: ".5px",
            '@media (max-width:600px)': {
                fontSize: 14,
            },
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                    '&:hover': {
                        textTransform: "none",
                        "&:hover": {
                            boxShadow: 'none',
                            outline: 'none'
                        }
                    }
                },
                contained: {
                    backgroundColor: "#007ECA",
                    width: "fit-content",
                    border: 'none',
                    color: '#FFFFFF',
                    letterSpacing: "1px",
                    "&:hover": {
                        backgroundColor: '#007ECA',
                        border: 'none',
                    }
                },
                standard: {
                    backgroundColor: "#017BCC",
                    width: "112px",
                    border: 'none',
                    color: '#FFFFFF',
                    letterSpacing: "1px",
                    padding: "10px",
                    border: "0.5px solid #1A2461",
                    borderRadius: "8px",
                    fontSize: "20px",
                    fontWeight: "700",
                    "&:hover": {
                        border: "0.5px solid #1A2461 !important",
                        backgroundColor: '#017BCC',
                    }
                }
            }
        },
        MuiTableContainer: {
            styleOverrides: {
                root: {
                    border: ".5px solid #737373",
                    borderBottom: "none"

                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                root: {
                    borderBottom: ".5px solid #737373",
                    textAlign: "center"
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    fontWeight: 500,
                    fontSize: 16,
                    letterSpacing: ".3px",
                    color: "#737373",
                    marginBottom: "16px"
                }
            }
        },
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    color: "#003893",
                    '&.Mui-checked': { color: "#003893", }
                }
            }
        },
        MuiTextField: {
            styleOverrides: {
                root: {
                    '.MuiOutlinedInput-notchedOutline': {
                        borderRadius: "8px",
                        border: ".1px solid #1A2461"
                    },
                    ' .css-wlay5r-MuiInputBase-root-MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline': {
                        borderWidth: "0px",
                        border: ".1px solid #1A2461",
                        borderColor: "none !important"
                    }
                }
            }
        }
    }
})

Theme = responsiveFontSizes(Theme)


export default Theme