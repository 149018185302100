import React from 'react'
import { Box, Grid, Stack, Typography, Button, IconButton } from "@mui/material"
import Carousel from "../Carousel"
import { ReactComponent as RightUpArrowIcon } from "../../Icons/rightUpArrowWhite.svg"


export default function Hostels() {
    return (
        <>
            <Box mt={10} px={5} width="100%">
                <Stack alignItems="center">
                    <Typography p={2} width="fit-content" variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">HOSTELS</Typography>
                </Stack>
                <Grid container mt={5} p={3} rowGap={2} columnGap={3} sx={{ background: "#EFF4FC", borderRadius: "20px" }}>
                    <Grid xs={12} md={4.5}>
                        <Stack margin="0 auto" sx={{ maxWidth: "440px", maxHeight: "300px", borderRadius: "24px" }}>
                            <Carousel />
                        </Stack>
                    </Grid>
                    <Grid xs={12} md={7}>
                        <Stack textAlign="left" height="100%">
                            <Typography mt={2} variant='h4_1'>Vinga Hall @ RG</Typography>
                            <Typography mt={3} variant='h9_1'>About the Hostel</Typography>
                            <Typography mt={1} variant='h9_4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla.</Typography>
                            <Stack direction="row" justifyContent="end" alignItems='end' height="100%">
                                <Button variant="contained" endIcon={<RightUpArrowIcon />} sx={{ width: "100px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>View</Button>
                            </Stack>
                        </Stack>

                    </Grid>
                </Grid>
                <Grid container mt={5} p={3} rowGap={2} columnGap={3} sx={{ background: "#EFF4FC", borderRadius: "20px" }}>
                    <Grid xs={12} md={4.5}>
                        <Stack margin="0 auto" sx={{ maxWidth: "440px", maxHeight: "300px", borderRadius: "24px" }}>
                            <Carousel />
                        </Stack>
                    </Grid>
                    <Grid xs={12} md={7}>
                        <Stack textAlign="left" height="100%">
                            <Typography mt={2} variant='h4_1'>Vinga Hall @ RG</Typography>
                            <Typography mt={3} variant='h9_1'>About the Hostel</Typography>
                            <Typography mt={1} variant='h9_4'>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla.</Typography>
                            <Stack direction="row" justifyContent="end" alignItems='end' height="100%">
                                <Button variant="contained" endIcon={<RightUpArrowIcon />} sx={{ width: "100px", height: "40px", padding: "10px 30px", backgroundColor: "#007eca", borderRadius: "80px", color: "white", textTransform: "none", letterSpacing: "1px", fontWeight: 700, fontSize: "16" }}>View</Button>
                            </Stack>
                        </Stack>

                    </Grid>
                </Grid>
            </Box >
        </>
    )
}
