import React from 'react'
import { menus } from '../Data'
import { Box, Typography } from "@mui/material"
import { useNavigate } from 'react-router-dom'


export default function Menu() {
    const navigate = useNavigate()

    return (
        <Box columnGap="20px" sx={{ display: { md: "flex", xs: "none" }, flexDirection: "row" }}>
            {menus?.map((item, index) => {
                return (
                    <>
                        <Box key={index} onClick={() => navigate(item?.uri_link)} mt="20px" pt={3} sx={{ display: "flex", flexDirection: "column", width: { lg: 90, md: 70, sm: 60 }, height: "100px", '&:hover': { borderBottom: "3px solid white", transition: "0.2s" } }}>
                            <Box display="flex" justifyContent="center">
                                <Box component="img" src={item?.icon} ></Box>
                            </Box>
                            <Typography textAlign="center" mt="10px" variant="h9_3" color="white">{item?.nav_bar_name}</Typography>
                        </Box>
                    </>
                )
            })}

        </Box>
    )
}
