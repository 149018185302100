import React from 'react'
import { Box, Typography, Stack } from '@mui/material'
import { ReactComponent as LogoIcon } from "../Icons/logo.svg"
import { ReactComponent as LocationIcon } from "../Icons/location.svg"
import { ReactComponent as PhoneIcon } from "../Icons/phone.svg"


export default function Footer() {
    return (
        <>
            <Stack py={3} px={{ md: "60px", xs: '10px' }} columnGap={7} direction={{ md: "row" }} rowGap={3} alignItems="center" backgroundColor="#003893" height="auto" minHeight="180px">
                <LogoIcon />
                <Stack rowGap="20px" textAlign="left">
                    <Typography variant="h9_1" color="white" letterSpacing="1px">VingaHall @ RG</Typography>
                    <Stack columnGap="10px" direction="row" alignItems="center">
                        <PhoneIcon />
                        <Typography variant="h9_3" color="white">+65 6962 6868 / +65 6677 6789</Typography>
                    </Stack>
                    <Stack columnGap="10px" direction="row" alignItems="center">
                        <LocationIcon fill="#27BAC4" />
                        <Typography variant="h9_3" color="white">No.99 Rangoon Road Singapore 218384</Typography>
                    </Stack>
                </Stack>
                <Stack rowGap="20px" textAlign="left">
                    <Typography variant="h9_1" color="white">VingaHall @ BK</Typography>

                    <Stack columnGap="10px" direction="row" alignItems="center">
                        <PhoneIcon />
                        <Typography variant="h9_3" color="white">+65 6962 6868 / +65 6677 6789</Typography>
                    </Stack>
                    <Stack columnGap="10px" direction="row" alignItems="center">
                        <LocationIcon fill="#27BAC4" />
                        <Typography variant="h9_3" color="white">10/12 Beng Wan Road, Singapore 339842</Typography>
                    </Stack>
                </Stack>
            </Stack>
            <Stack px="60px" columnGap={7} direction="row" alignItems="center" backgroundColor="#017BCC" height="50px">
                <Typography variant="h9_3" color="white">© Copyright 2024 Vinga international. All Rights Reserved.</Typography>
            </Stack>
        </>
    )
}
