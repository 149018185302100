import MainIcon from "../Icons/Menus/main.svg"
import IntroIcon from "../Icons/Menus/intro.svg"
import LinksIcon from "../Icons/Menus/links.svg"
import NewsIcon from "../Icons/Menus/news.svg"
import VisitorIcon from "../Icons/Menus/visitor.svg"
import ServicesIcon from "../Icons/Menus/services.svg"
import ApplicationIcon from "../Icons/Menus/application.svg"
import Step1 from "../Icons/StepIcons/step1.svg"
import Step2 from "../Icons/StepIcons/step2.svg"
import Step3 from "../Icons/StepIcons/step3.svg"
import Step4 from "../Icons/StepIcons/step4.svg"
import Step5 from "../Icons/StepIcons/step5.svg"
import Step6 from "../Icons/StepIcons/step6.svg"


export const menus = [
    {
        id: 1,
        nav_bar_name: "Main",
        isdrobdown: 0,
        uri_link: "/",
        icon: MainIcon,
    },
    {
        id: 2,
        nav_bar_name: "Intro",
        isdrobdown: 0,
        uri_link: "intro",
        icon: IntroIcon
    },
    {
        id: 3,
        nav_bar_name: "Application",
        isdrobdown: 0,
        uri_link: "application",
        icon: ApplicationIcon
    },
    {
        id: 4,
        nav_bar_name: "Services",
        isdrobdown: 0,
        uri_link: "services",
        icon: ServicesIcon
    },
    {
        id: 5,
        nav_bar_name: "News",
        isdrobdown: 0,
        uri_link: "news",
        icon: NewsIcon
    },
    {
        id: 6,
        nav_bar_name: "Visitor",
        isdrobdown: 0,
        uri_link: "visitor",
        icon: VisitorIcon
    },
    {
        id: 7,
        nav_bar_name: "Links",
        isdrobdown: 0,
        uri_link: "links",
        icon: LinksIcon
    }
]

export const Steps = [
    {
        id: 1,
        name: "Step 1",
        Description: "Choose Type",
        icon: Step1,
    },
    {
        id: 2,
        name: "Step 2",
        Description: "Information Form",
        icon: Step1,
    },
    {
        id: 3,
        name: "Step 3",
        Description: "Confirm Payment",
        icon: Step3,
    },
    {
        id: 4,
        name: "Step 4",
        Description: "Pending Confirmation",
        icon: Step4,
    },
    {
        id: 5,
        name: "Step 5",
        Description: "Contract signing & Payment Submission",
        icon: Step5,
    },
    {
        id: 6,
        name: "Step 6",
        Description: "Fly to Singapore, Start a new life",
        icon: Step6,
    },
]