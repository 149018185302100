import logo from './logo.svg';
import './App.css';
import { Typography } from "@mui/material"
import Dashboard from './Pages/Dashboard';
import { BrowserRouter, Routes, Route } from "react-router-dom"
import Main from './Pages/Main';
import NewsReview from './Pages/News/NewsReview';
import NewsList from './Pages/News/NewsList';
import ApplicationDetails from './Pages/Application/ApplicationDetails.jsx';

function App() {
  return (
    <div className="App">
      {/* <Dashboard > */}
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Dashboard />} >
            <Route index element={<Main />} />
            <Route path="/news" element={<NewsList />} />
            <Route path="application" element={<ApplicationDetails />} />
            <Route path="/*" element={<h1>Page Not Found</h1>} />
          </Route>
        </Routes>
      </BrowserRouter>
      {/* </Dashboard> */}

    </div >
  );
}

export default App;
