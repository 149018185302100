import React, { useState } from 'react'
import AnnouncementImg from "../../Icons/AnnouncementBg.svg"
import { ReactComponent as BellIcon } from "../../Icons/BellIcon.svg"
import { ReactComponent as ApplicationIcon } from "../../Icons/application.svg"
import { ReactComponent as RightUpArrowIcon } from "../../Icons/rightUpArrow.svg"
import { ReactComponent as Course_trainingIcon } from "../../Icons/Announcement/course_training.svg"
import { ReactComponent as Dental_appointmentIcon } from "../../Icons/Announcement/dental_appointment.svg"
import { ReactComponent as English_trialclaIcon } from "../../Icons/Announcement/english_trial_cls.svg"
import { ReactComponent as Extracurricular_activitiesIcon } from "../../Icons/Announcement/extracurricular_activities.svg"
import { ReactComponent as Rent_paymentIcon } from "../../Icons/Announcement/rent_payment.svg"
import { ReactComponent as Repair_applicationIcon } from "../../Icons/Announcement/repair_application.svg"
import { ReactComponent as Weekend_activitiesIcon } from "../../Icons/Announcement/weekend_activities.svg"
import { ReactComponent as University_tutionIcon } from "../../Icons/Announcement/university_tution.svg"
import { ReactComponent as Subject_tutionIcon } from "../../Icons/Announcement/subject_tution.svg"
import { Box, Grid, Stack, Typography } from "@mui/material"

export default function Announcement() {

    const [announcementdata, setAnnouncementData] = useState([1, 1, 1, 1, 1])

    return (
        <Box mt={22}>
            <Stack alignItems="center">
                <Typography p={2} width="fit-content" variant="h6_1" color="primary" border="1px solid #27BAC4" padding="10px 50px" borderRadius="50px" letterSpacing="1.1px">ANNOUNCEMENTS</Typography>
            </Stack>
            <Grid mt={6} py={5} gap={10} justifyContent="center" container sx={{ backgroundColor: "#EFF4FC" }}>

                <Grid xs={12} md={5}>
                    <Box component="img" src={AnnouncementImg} ></Box>
                    {/* <img src={AnnouncementImg} /> */}
                </Grid>
                <Grid display="flex" flexDirection="column" justifyContent="start" alignItems="center" xs={12} md={5}>
                    {/* <Stack> */}
                    <Stack sx={{ backgroundColor: "white", border: "0.5px solid #27BAC4", borderRadius: "20px", height: "449px", width: "550px", }}>
                        <Typography p={2} variant="h6_2" color="primary" textAlign="start" borderBottom="0.5px solid #27BAC4">Announcements</Typography>
                        <Stack rowGap='20px' p={3} sx={{ overflowY: "scroll", "&::-webkit-scrollbar": { display: "none" } }}>
                            {announcementdata?.map((itm, index) => (
                                <>
                                    <Stack direction="row" justifyContent="space-between" height="53px">
                                        <Stack direction="row" columnGap="10px" alignItems="center">
                                            <Box sx={{ width: "45px", height: "45px", borderRadius: "100px", backgroundColor: "button", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                                <BellIcon />
                                            </Box>
                                            <Box display="flex" flexDirection="column" textAlign="start">
                                                <Typography variant="h6_1" color="text_blue">Water Outage</Typography>
                                                <Typography variant="h9_2" color="text_blue">Water outage from 09AM to 10AM</Typography>
                                            </Box>
                                        </Stack>
                                        <Stack alignItems="end">
                                            <Typography variant="h9_2" color="#606060">08:20 AM</Typography>
                                            <Typography variant="h9_2" color="#606060">2024.01.30</Typography>
                                        </Stack>
                                    </Stack>
                                </>
                            ))}
                        </Stack>
                    </Stack>
                    {/* </Stack> */}

                    <Grid justifyContent="center" rowGap="30px" columnGap="45px" mt={3} container>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <ApplicationIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">VingaHall <br /> Application</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Rent_paymentIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">Rent
                                    <br /> Payment</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Subject_tutionIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">Subject
                                    <br /> Tuition</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <University_tutionIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">University
                                    <br /> Tuition</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Course_trainingIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">Course
                                    <br /> Training</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <English_trialclaIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">English Trial
                                    <br /> Class</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Weekend_activitiesIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">Weekend
                                    <br /> Activities</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Extracurricular_activitiesIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">Extracurricular
                                    <br /> Activities</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Dental_appointmentIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">Q & M Dental
                                    <br /> Appointment</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>
                        <Grid>
                            <Stack columnGap="19px" direction="row" alignItems="center" sx={{ backgroundColor: "#DDF1F0", width: "240px", height: "70px", padding: "15px 20px", borderRadius: '20px', border: "0.5px solid #27BAC4" }}>
                                <Box sx={{ width: "56px", height: "56px", borderRadius: "100px", backgroundColor: "#2DB7C2", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                    <Repair_applicationIcon />
                                </Box>
                                <Typography variant="h9_1" color="#1A2461">Repair
                                    <br /> Application</Typography>
                                <RightUpArrowIcon />
                            </Stack>
                        </Grid>

                    </Grid>

                </Grid>
            </Grid >
        </Box >
    )
}
