import React, { useState } from 'react'
import { Typography, Stack, Box, Stepper, Step, StepLabel, StepButton, setStepperCount } from '@mui/material'
import { ReactComponent as StepperCorrectIcon } from "../../../Icons/StepperCorrectIcon.svg"
import Room from './Room';
import Services from './Services';
import Information from './Information';


const steps = ["Room", "Services", "Information", "Review", "Payment", "Confirmation"];

export default function ApplicationDetails() {

    const [stepperCount, setStepperCount] = useState(1)

    const StepperIcon = (index) => {
        if (stepperCount > index + 1) {
            return <StepperCorrectIcon />
        }
        else if (stepperCount == index + 1) {
            return <Typography width="36px" height="36px" backgroundColor="text_blue" borderRadius="100px" color="white" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{index + 1}</Typography>
        }
        else {
            return <Typography width="36px" height="36px" backgroundColor="white" border=".5px solid #737373" borderRadius="100px" color="#737373" sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}>{index + 1}</Typography>
        }
    }


    return (
        <>
            <Box>
                <Stack direction="row" justifyContent="space-between" alignItems="center" padding="42px 60px">
                    <Stack gap="10px">
                        <Typography variant="h4_1">Hostel application process</Typography>
                        <Typography variant="h9_4" color="neutral_4">Fill in the required details</Typography>
                    </Stack>
                    <Typography variant="h9_4" color="neutral_4">Saved</Typography>
                </Stack>
                <Box px={8} py={3} mb={5} sx={{ width: '100%', borderTop: ".5px solid #1A2461", borderBottom: ".5px solid #1A2461" }}>
                    <Stepper nonLinear activeStep={1}>
                        {steps.map((label, index) => (
                            <Step key={label}>
                                <StepButton color="inherit" icon={StepperIcon(index)}>
                                    <Typography variant="h8_1" color={(stepperCount == index + 1) ? "text_blue" : (stepperCount > index + 1) ? "green" : "neutral_4"}> {label}</Typography>
                                </StepButton>
                            </Step>
                        ))}
                    </Stepper>
                </Box>
                {stepperCount === 1 && <Room setStepperCount={setStepperCount} />}
                {stepperCount === 2 && <Services setStepperCount={setStepperCount} />}
                {/* {stepperCount === 3 && <Information setStepperCount={setStepperCount} />} */}

            </Box>
        </>
    )
}
