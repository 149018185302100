import React from 'react'
import { Typography, Stack, Box, Grid, Divider, Radio, Select, MenuItem, InputLabel, Button } from '@mui/material'
import { ReactComponent as DownArrowIcon } from "../../../Icons/DownArrow.svg"

export default function Room({ setStepperCount }) {



    return (
        <Box px={{ md: 10, xs: 3 }}>
            <Typography variant="h6_1">Select Preferred room</Typography>

            <Grid mt={3} mb={6} container rowGap={7}>
                <Grid xs={12}>
                    <Typography variant='h7_1' color="button">Vinga Hall @ RG (Boarding House)</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Twin Room with Bathroom</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Window</Typography>
                            <Box variant='contained' sx={{ height: '40px', width: "180px", backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", color: "text_blue", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", p: "10px", columnGap: "10px" }}>With window  <DownArrowIcon fill="#1A2461" /></Box>
                        </Stack>
                    </Stack>
                    <Stack mt={2} direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Single Room with Bathroom</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Window</Typography>
                            <Box variant='contained' sx={{ height: '40px', width: "180px", backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", color: "text_blue", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", p: "10px", columnGap: "10px" }}>With window  <DownArrowIcon fill="#1A2461" /></Box>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12}>
                    <Typography variant='h7_1' color="button">Vinga Hall @ RG (Room For Rent)</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Twin Room with Bathroom</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Window</Typography>
                            <Box variant='contained' sx={{ height: '40px', width: "180px", backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", color: "text_blue", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", p: "10px", columnGap: "10px" }}>With window  <DownArrowIcon fill="#1A2461" /></Box>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12}>
                    <Typography variant='h7_1' color="button">Vinga Hall @ Boon Keng (Boarding House)</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Twin Sharing</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Room</Typography>
                            <Box variant='contained' sx={{ height: '40px', width: "180px", backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", color: "text_blue", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", p: "10px", columnGap: "10px" }}>Master Room <DownArrowIcon fill="#1A2461" /></Box>
                        </Stack>
                    </Stack>
                    <Stack mt={2} direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Single</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Room</Typography>
                            <Box variant='contained' sx={{ height: '40px', width: "180px", backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", color: "text_blue", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", p: "10px", columnGap: "10px" }}>Master Room <DownArrowIcon fill="#1A2461" /></Box>
                        </Stack>
                    </Stack>
                </Grid>
                <Grid xs={12}>
                    <Typography variant='h7_1' color="button">Vinga Hall @ Boon Keng (Room For Rent)</Typography>
                    <Divider sx={{ my: 2 }} />
                    <Stack direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Twin Sharing</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Room</Typography>
                            <Box variant='contained' sx={{ height: '40px', width: "180px", backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", color: "text_blue", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", p: "10px", columnGap: "10px" }}>Master Room  <DownArrowIcon fill="#1A2461" /></Box>
                        </Stack>
                    </Stack>
                    <Stack mt={2} direction="row" alignItems="center" justifyContent="space-between" sx={{ border: "0.5px solid #1A2461", padding: "23px", borderRadius: "8px" }}>
                        <Stack direction="row" alignItems="center">
                            <Radio />
                            <Typography variant='h7_1'>Single</Typography>
                        </Stack>
                        <Stack direction="row" alignItems="center" gap="16px">
                            <Typography variant='h7_1' sx={{ fontSize: "14px !important", color: "neutral_7" }}>Room</Typography>
                            <Box variant='contained' sx={{ height: '40px', width: "180px", backgroundColor: "#FFFFFF", border: ".5px solid #1A2461", borderRadius: "8px", color: "text_blue", display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", p: "10px", columnGap: "10px" }}>Master Room  <DownArrowIcon fill="#1A2461" /></Box>
                        </Stack>
                    </Stack>
                </Grid>
            </Grid>

            <Typography variant='h7_1'>Room details</Typography>
            <Divider sx={{ my: 2 }} />
            <Grid container justifyContent="space-between">
                <Grid md={5.5}>
                    <Typography variant='h7_1'>Contract Length</Typography><br />
                    <InputLabel sx={{ mt: 3 }} variant="standard"> Contract Length</InputLabel>
                    <Select fullWidth >
                        <MenuItem value={1}>With window</MenuItem>
                    </Select>
                </Grid>
                <Grid md={5.5} gap={1} textAlign="center" display="flex" flexDirection="column" alignItems="center" justifyContent="center">
                    <Typography variant='h7_1'>Selected room preview</Typography>
                    <Box mt={1} component="img" sx={{ width: { sm: 450, xs: "100%" }, height: { sm: 450, xs: 'auto' }, objectFit: "cover", border: ".5px solid #1A2461", borderRadius: "8px" }} src="https://images.pexels.com/photos/271624/pexels-photo-271624.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"></Box>
                    <Typography variant='h7_1' color="button">Vinga Hall @ RG (Boarding House)</Typography>
                    <Typography variant='h7_1' color="#003893">Twin room with bathroom</Typography>
                    <Typography variant='h7_1' color="#003893">With window</Typography>
                    <Typography variant='h7_1' color="#27BAC4">S$ 3000/month</Typography>
                </Grid>
            </Grid>
            <Stack my={5} mx={5} alignItems="end">
                <Button onClick={() => { setStepperCount(2) }} variant="standard">Continue</Button>
            </Stack>
        </Box>
    )
}
